import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import { Notification } from 'react-admin';
import { useTranslate, useLogin, useNotify } from 'ra-core';
import { lightTheme } from '../layout/themes';
import { Location } from 'history';
import logo from "../images/logo.png";
import * as apiClient from "../apiClient";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";
import queryString from 'query-string';

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'teal',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        width: 450,
        maxWidth: "100%",
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
    backoffice:{
        margin:10,
        marginTop:20,
    },
    adminlink:{

    },
}));
interface IMeta {
    touched: boolean;
    error: Error;
}

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
        <TextField variant="outlined"
            error={!!(touched && error)}
            helperText={touched && error}
            {...inputProps}
            {...props}
            fullWidth
        />
    );

interface FormValues {
    firstname?: string;
    lastname?: string;
    email?: string;
    mobile?: string;
    username?: string;
    password?: string;
    otp?: string;
}

const { Form } = withTypes<FormValues>();

const LoginByOTP = ({ location }: { location: Location }) => {
    const [loading, setLoading] = useState(false);
    const classes = useStyles({});
    const translate = useTranslate();
    const notify = useNotify();
    const login = useLogin();
    let history = useHistory();

    const params = queryString.parse(location.search);
    if (params.invite_code) {
        localStorage.setItem("INVITE_CODE", params.invite_code.toString());
    }else{
        localStorage.removeItem("INVITE_CODE");
    }

    const handleSubmit = (auth: FormValues) => {
        setLoading(true);
        localStorage.setItem("mobile", auth.mobile!);
        apiClient.getOTP(auth.mobile).then((response) => {
            if (response[0].result) {
                history.push("/verifyotp");
            } else {
                setLoading(false);
                notify(response[0].message, 'error');
            }

        }).catch((error) => {
            console.log(error);
            setLoading(false);
            notify(
                typeof error === 'string'
                    ? error
                    : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                'warning'
            );
        });

        /* login(auth, location.state ? location.state.nextPathname : '/').catch(
            (error: Error) => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                            ? 'ra.auth.sign_in_error'
                            : error.message,
                    'warning'
                );
            }
        ); */
    };

    const validate = (values: FormValues) => {
        const errors: FormValues = {};
        if (!values.mobile) {
            errors.mobile = translate('ra.validation.required');
        }
        if(values.mobile && values.mobile.length > 10){
            errors.mobile = "Mobile number exceeds 10 digits.";
        }
        return errors;
    };

    return (
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.main}>
                        <Card className={classes.card}>
                            <div className={classes.avatar}>
                                <Avatar className={classes.icon}>
                                    <HowToRegIcon />
                                </Avatar>
                            </div>
                            <div className={classes.hint}>
                                <img src={logo} />
                            </div>
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <Field
                                        name="mobile"
                                        // @ts-ignore
                                        component={renderInput}
                                        label="Mobile Number"
                                        disabled={loading}
                                    />
                                </div>
                            </div>
                            <CardActions className={classes.actions}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    fullWidth
                                >
                                    Login
                                </Button>
                                <Button variant="outlined" color="secondary"
                                    fullWidth
                                    href="#register">
                                    Sign Up
                                </Button>
                            </CardActions>
                        </Card>
                        <Notification />
                        <div className={classes.backoffice}>
                            <a className={classes.adminlink} href="#admin">Backoffice Login</a>
                        </div>
                    </div>
                </form>
            )}
        />
    );
};

LoginByOTP.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles({}) hook used in Register won't get
// the right theme
const LoginByOTPWithTheme = (props: any) => (
    <ThemeProvider theme={createMuiTheme(lightTheme)}>
        <LoginByOTP {...props} />
    </ThemeProvider>
);

export default LoginByOTPWithTheme;
